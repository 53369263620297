import React from "react";
import { Link } from "react-router-dom";
import HomeLayout from "../components/HomeLayout";
import { motion } from "framer-motion";

export default function Privacy() {
  return (
    <HomeLayout>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main>
          <section class="bg-white" id="terms">
            <div class="pt-10">
              <div class="text-center py-16">
                <div className="max-w-screen-md px-4 mx-auto">
                  <h2 class="text-3xl lg:text-5xl leading-normal font-extrabold text-black">Privacy Statement</h2>
                </div>
              </div>
              <section className="container text-[#444444]">
                <div>
                  <p>Last updated: March 1st, 2024</p>

                  <p>This Privacy Statement describes the personal data we collect and/or process (which may include collecting, organizing, structuring, storing, using, or disclosing) to provide products and services offered directly by Perplexity Solved Solutions, Inc. (“Perplexity”), including Perplexity’s websites, its meetings, webinars, and messaging platform, related collaborative features, and Perplexity App Marketplace (“Perplexity products and services” or “products and services”). Perplexity products and services covered in this Privacy Statement do not include products or services developed by Perplexity that are covered under a separate privacy policy.</p>

                  <p>
                    California residents, please see our <a href="#californianotice">California Privacy Notice at Collection</a>, and <a href="#californiarights">California &amp; Other U.S. State Privacy Rights</a> sections.
                  </p>

                  <ul className="list-decimal list-inside text-blue-500 my-8">
                    <li>
                      <a href="#whatdata">What Personal Data Do We Receive?</a>
                    </li>
                    <li>
                      <a href="#howusedata">How Do We Use Personal Data?</a>
                    </li>
                    <li>
                      <a href="#cookies">Cookies Statement</a>
                    </li>
                    <li>
                      <a href="#howsharedata">How Do We Share Personal Data?</a>
                    </li>
                    <li>
                      <a href="#whocansee">Who Can See, Share, and Process My Personal Data When I Join Meetings and Use Other Perplexity Products and Services?</a>
                    </li>
                    <li>
                      <a href="#rights">Privacy Rights and Choices</a>
                    </li>
                    <li>
                      <a href="#children">Children</a>
                    </li>
                    <li>
                      <a href="#contactus">How to Contact Us</a>
                    </li>
                    <li>
                      <a href="#retention">Retention</a>
                    </li>
                    <li>
                      <a href="#dataprotection">European Data Protection Specific Information</a>
                    </li>
                    <li>
                      <a href="#californianotice">California Notice at Collection</a>
                    </li>
                    <li>
                      <a href="#californiarights">California &amp; Other U.S. State Privacy Rights</a>
                    </li>
                    <li>
                      <a href="#changes">Changes to This Privacy Statement</a>
                    </li>
                  </ul>
                  <br />
                  <div id="whatdata">
                    <h2>What Personal Data Do We Receive?</h2>
                    <div>
                      <p>Personal data is any information from or about an identified or identifiable person, including information that Perplexity can associate with an individual person. We may collect, or process on behalf of our customers, the following categories of personal data when you use or interact with Perplexity products and services:</p>
                      <ul>
                        <li>
                          <b>Account Information</b>: Information associated with an account that licenses Perplexity products and services, which may include administrator name, contact information, account ID, billing and transaction information, and account plan information.
                        </li>

                        <li>
                          <b>Profile and Participant Information: </b>Information associated with the Perplexity profile of a user who uses Perplexity products and services under a licensed account or that is provided by an unlicensed participant joining a meeting, which may include name, display name, picture, email address, phone number, job information, stated locale, user ID, or other information provided by the user and/or their account owner.
                        </li>

                        <li>
                          <b>Contact Information</b>: Contact information added by accounts and/or their users to create contact lists on Perplexity products and services, which may include contact information a user integrates from a third-party app or provided by users to process referral invitations.
                        </li>

                        <li>
                          <b>Settings</b>: Information associated with the preferences and settings on a Perplexity account or user profile, which may include audio and video settings, recording file location, screen sharing settings, and other settings and configuration information.
                        </li>

                        <li>
                          <b>Registration Information</b>: Information provided when registering for a Perplexity meeting, webinar, Perplexity Room, or recording, which may include name and contact information, responses to registration questions, and other registration information requested by the host.
                        </li>

                        <li>
                          <b>Device Information</b>: Information about the computers, phones, and other devices used when interacting with Perplexity products and services, which may include information about the speakers, microphone, camera, OS version, hard disk ID, PC name, MAC address, IP address (which may be used to infer general location at a city or country level), device attributes (like operating system version and battery level), WiFi information, and other device information (like Bluetooth signals).
                        </li>

                        <li>
                          <b>Content and Context from Meetings, Webinars, Messaging, and Other Collaborative Features:</b> Content generated in meetings, webinars, or messages that are hosted on Perplexity products and services, which may include audio, video, in-meeting messages, in-meeting and out-of-meeting whiteboards, chat messaging content, transcriptions, transcript edits and recommendations, written feedback, responses to polls and Q&amp;A, and files, as well as related context, such as invitation details, meeting or chat name, or meeting agenda. Content may contain your voice and image, depending on the account owner’s settings, what you choose to share, your settings, and what you do on Perplexity products and services.
                        </li>

                        <li>
                          <b>Usage Information Regarding Meetings, Webinars, Messaging, Collaborative Features and the Website: </b>Information about how people and their devices interact with Perplexity products and services, such as: when participants join and leave a meeting; whether participants sent messages and who they message with; performance data; mouse movements, clicks, keystrokes or actions (such as mute/unmute or video on/off), edits to transcript text, where authorized by the account owner and other inputs that help Perplexity to understand feature usage, improve product design, and suggest features; which third-party apps are added to a meeting or other product or service and what information and actions the app is authorized to access and perform; use of third-party apps and the Perplexity App Marketplace; features used (such as screen sharing, emojis, or filters); and other usage information and metrics. This also includes information about when and how people visit and interact with Perplexity’s websites, including what pages are accessed, interaction with website features, and whether &nbsp;the person signed up for a Perplexity product or service.
                        </li>

                        <li>
                          <b>Limited Information from Perplexity Email and Calendar Services: </b>“Perplexity Email” refers to Perplexity’s native email service and emails sent from Perplexity’s native email service. Perplexity Email is designed to be end-to-end encrypted by Perplexity by default for emails sent and received directly between active Perplexity Email users. Support for end-to-end encryption requires Perplexity Email users to have added a device to their Perplexity Email account with the associated email address and to use a supported Perplexity client. When an email is end-to-end encrypted, only the users, and, depending on their settings, account owners, or designated account administrators control the encryption key and therefore access to the email content, including body text, subject line, attachments and custom labels applied to messages by users in their inboxes. Emails sent to or received from non-Perplexity Email users are encrypted after the email is sent or received from Perplexity’s servers, if the Perplexity Email user chooses to send them with encryption. In all cases, Perplexity does have access to email metadata used for basic email delivery—specifically, email addresses in the from to, cc, and bcc fields, time, mime ID, and the number and size of attachments. From use of Perplexity’s native calendar service, Perplexity receives information regarding meeting invitations, body text, sender and recipients, and other calendar information.
                        </li>

                        <li>
                          <b>Content from Third-Party Integrations:</b> Users can access email and calendars from third-party services through their Perplexity client, if they choose to integrate them. This information is not end-to-end encrypted by Perplexity, but Perplexity employees do not access this content, unless directed to, or required for legal, safety, or security reasons. If account owners and/or their users integrate their third-party emails with products and services offered or powered by Perplexity, such as our business analytics tools like Reports Detective, Perplexity may collect or process email information, including email content, headers and metadata, from such third-party services in order to provide services requested by the account and to improve the product.
                        </li>

                        <li>
                          <b>Communications with Perplexity: </b>Information about your communications with Perplexity, including relating to support questions, your account, and other inquiries.
                        </li>

                        <li>
                          <b>Information from Partners</b>: Perplexity obtains information about account owners and their users from third-party companies, such as market data enrichment services, including information about an account owner’s company size or industry, contact information, or activity of certain enterprise domains. Perplexity may also obtain information from third-party advertising partners who deliver ads displayed on Perplexity products and services, such as whether you clicked on an ad they showed you.
                        </li>
                      </ul>
                      <p>In certain jurisdictions, some of the personal data Perplexity receives may be considered sensitive. Please see “California &amp; Other U.S. State Privacy Rights” for more information.</p>
                    </div>
                  </div>
                  <div id="howusedata">
                    <h2>How Do We Use Personal Data?</h2>

                    <p>Perplexity employees do not access meeting, webinar, messaging or email content (specifically, audio, video, files, in-meeting whiteboards, messaging or email contents), or any content generated or shared as part of other collaborative features (such as out-of-meeting whiteboards), unless authorized by an account owner, or as required for legal, safety, or security reasons, as discussed below, and where technically feasible. Perplexity uses personal data to conduct the following activities:</p>

                    <p>
                      1.<span>&nbsp;</span>
                      <b>Provide Perplexity Products and Services: </b>To provide and develop products and services to account owners, their users, and those they invite to join meetings and webinars hosted on their accounts, including to customize Perplexity products and services and recommendations for accounts or their users. Perplexity also uses personal data to determine what products and services may be available in your location, and uses personal data, including contact information, to route invitations, messages, or Perplexity Emails to recipients when users send or receive invitations, messages, or Perplexity Emails using Perplexity products and services. This may also include using personal data for customer support, which may include accessing audio, video, files, messages, and other content or context, at the direction of the account owner or their users. We also use personal data to manage our relationship and contracts with account owners and others, including billing, compliance with contractual obligations, facilitating payment to third-party developers in relation to purchases made through the Perplexity App Marketplace, and related administration:
                    </p>

                    <p>
                      1.<span>&nbsp;</span>Advanced Voice and Video Features: If you elect to use certain video features, if available, such as filters, studio effects, avatars, and gestures, information about your movements or the positioning of your face or hands may be processed on your device to apply the selected features. Such data does not leave your device, is not retained, is not used to identify you, and is only used to generate the selected effects. If certain other features are enabled, such as transcription generation for recordings, Perplexity may use technology that analyzes the meeting’s audio recording to distinguish one speaker from another to create an accurate transcript. The audio analysis is not retained after the transcript is generated.
                    </p>

                    <p>
                      2.<span>&nbsp;</span>Intelligent Features: Perplexity provides intelligent features and products, such as Reports Detective or other tools to recommend chat, email or other content, that may use artificial intelligence, machine learning, or other technology to analyze and process your content and personal data to develop, provide and improve intelligent features and products.{" "}
                    </p>

                    <p>
                      2.<span>&nbsp;</span>
                      <b>Product Research and Development: </b>To develop, test, and improve Perplexity products and services, including, for example, content-related features (such as background and other filters), and to troubleshoot products and services.
                    </p>

                    <p>
                      3.<span>&nbsp;</span>
                      <b>Marketing, Promotions, and Third-Party Advertising: </b>To permit Perplexity and/or its third-party marketing partners to market, advertise, and promote Perplexity products and services, including based on your product usage, information we receive from third-party partners, information you provide to process referral invitations, or if you visit our websites, information about how and when you visit, and your interactions with them. We may also use this information to provide advertisements to you relating to Perplexity products and services or to engage third party partners to analyze your interactions on our website or app or to deliver advertising to you. Perplexity does not use meeting, webinar, or messaging content (specifically, audio, video, files shared, in-meeting whiteboards, and messages), or any content generated or shared as part of other collaborative features (such as out-of-meeting whiteboards) for any marketing or promotions.
                    </p>

                    <p>
                      4.<span>&nbsp;</span>
                      <b>Authentication, Integrity, Security, and Safety: </b>To authenticate accounts and activity, detect, investigate, and prevent malicious conduct, fraudulent activity or unsafe experiences, address security threats, protect public safety, and secure Perplexity products and services.
                    </p>

                    <p>
                      5.<span>&nbsp;</span>
                      <b>Communicate with You: </b>We use personal data (including contact information) to communicate with you about Perplexity products and services, including product updates, your account, and changes to our policies and terms. We will also use your information to respond to you when you contact us.
                    </p>

                    <p>
                      6.<span>&nbsp;</span>
                      <b>Legal Reasons: </b>To comply with applicable law or respond to valid legal process, including from law enforcement or government agencies, to investigate or participate in civil discovery, litigation, or other adversarial legal proceedings, protect you, us, and others from fraudulent, malicious, deceptive, abusive, or unlawful activities, and to enforce or investigate potential violations of our Terms of Service or policies.
                    </p>

                    <p>Perplexity uses advanced tools to automatically scan content such as virtual backgrounds, profile images, incoming emails to Perplexity’s native email service from someone who is not a Perplexity Email user, and files uploaded or exchanged through chat, for the purpose of developing, providing, and improving products and services, and detecting and preventing violations of our terms or policies and illegal or other harmful activity. Perplexity employees may access such content where required for legal, safety, or security reasons or where authorized by an account owner.</p>
                  </div>
                  <div id="howsharedata">
                    <h2>How Do We Share Personal Data?</h2>

                    <p>Perplexity provides personal data to third parties only with consent or in one of the following circumstances (subject to your prior consent where required under applicable law):</p>

                    <p>
                      1.<span>&nbsp;</span>
                      <b>Resellers: </b>If an account owner licensed or purchased Perplexity products and services from a third-party reseller of Perplexity products and services, the reseller may be able to access personal data and content for users, including meetings, webinars, and messages hosted by the account owner.
                    </p>

                    <p>
                      2.<span>&nbsp;</span>
                      <b>Vendors: </b>Perplexity works with third-party service providers to provide, support, and improve Perplexity products and services and technical infrastructure, and for business services such as payment processing, including in relation to purchases made through the Perplexity App Marketplace. Perplexity may also work with third-party service providers to provide advertisements and business analytics regarding Perplexity products and services. These vendors can access personal data subject to contractual and technical requirements for protecting personal data and prohibiting them from using personal data for any purpose other than to provide services to Perplexity or as required by law. Perplexity may integrate third-party technology to provide advanced features, such as Apple’s TrueDepth technology, to process information on your device about face or hand dimensions and gestures to provide video effects. This information is processed on your device, and such information is neither received nor stored by either the third party, or Perplexity.
                    </p>

                    <p>
                      3.<span>&nbsp;</span>
                      <b>For Legal Reasons: </b>Perplexity may share personal data as needed to: (1) comply with applicable law or respond to, investigate, or participate in valid legal process and proceedings, including from law enforcement or government agencies; (2) enforce or investigate potential violations of its Terms of Service or policies; (3) detect, prevent, or investigate potential fraud, abuse, or safety and security concerns, including threats to the public; (4) meet our corporate and social responsibility commitments; (5) protect our and our customers’ rights and property; and (6) resolve disputes and enforce agreements.
                    </p>

                    <p>
                      4.<span>&nbsp;</span>
                      <b>Marketing, Advertising, and Analytics Partners: </b>Perplexity uses third-party marketing, advertising, and analytics providers: to provide statistics and analysis about how people are using Perplexity products and services, including our website; and to provide advertising and marketing for Perplexity products and services, including targeted advertising based on your use of our website. These third-party partners may receive information about your activities on Perplexity’s website through third-party cookies placed on Perplexity’s website. To opt out of our use of third-party cookies that share data with these partners, visit our cookie management tool, available in <a href="about:blank">Cookies Settings</a>. Where required by law, Perplexity will first obtain your consent before engaging in the marketing or advertising activities described.
                    </p>

                    <p>
                      5.<span>&nbsp;</span>
                      <b>Corporate Affiliates: </b>Perplexity shares personal information with corporate affiliates, such as SenGrid, Twillo, to provide integrated email notifications and consistent experiences across Perplexity products and services (such as email notification an account owner and end users or their user receive to their registered email accounts).
                    </p>

                    <p>
                      6.<span>&nbsp;</span>
                      <b>Change of Control: </b>We may share personal data with actual or prospective acquirers, their representatives and other relevant participants in, or during negotiations of, any sale, merger, acquisition, restructuring, or change in control involving all or a portion of Perplexity’s business or assets, including in connection with bankruptcy or similar proceedings.
                    </p>

                    <p>
                      7.<span>&nbsp;</span>
                      <b>Third-Party Developers: </b>If you purchase a third-party app or integration from the Perplexity App Marketplace, Perplexity may share information about the purchase with the third-party developer, to provide the app or integration.
                    </p>
                  </div>
                  <div id="cookies">
                    <h2>Cookie Statement</h2>
                    <p>
                      <span>This Cookie Statement explains how Perplexity Solved Solutions, Inc. (“Perplexity”) and our third-party partners use cookies and similar technologies (“Cookies”) on our websites, including services provided on those websites (referred to collectively as “Websites”).</span>
                    </p>
                    <p>
                      <span>Perplexity uses Cookies to enable basic functionality, analyze trends, understand when and how you visit and interact with Perplexity’s Websites, gather information about your device and settings, and to permit Perplexity and its marketing partners to provide and measure advertising to you relating to Perplexity products and services. . Depending on your jurisdiction, we ask for your prior consent for different types of Cookies or allow you to opt-out.</span>
                    </p>
                    <p>
                      <b>
                        <span>What are Cookies and How Does Perplexity Use Them?</span>
                      </b>
                    </p>
                    <p>
                      <span>Cookies are small text, image or data files or tag code snippets placed on your device by websites that you visit or access. They are widely used to make websites work and function with greater efficiency, and to provide customization and to improve website performance. Some Cookies stay on your device only for the duration of your web session and expire when you exit your browser; other Cookies remain longer than your web session, including after you exit your browser, for example to remember you when you return to the website. </span>
                    </p>
                    <p>
                      <span>The table below explains the categories of Cookies that Perplexity and our third-party partners use on our Websites and why. Depending on your jurisdiction, we get permission before we set certain cookies and you can always prevent Perplexity from setting cookies that are not necessary for the performance of the Perplexity service. Once you make such a cookie selection, Perplexity stores a “preference” cookie on your device for at least six (6) months to prevent repeatedly asking you to set your cookie preferences. </span>
                    </p>
                  </div>
                  <div id="whocansee">
                    <h2>Who Can See, Share, and Process My Personal Data When I Join Meetings and Use Other Perplexity Products and Services?</h2>

                    <p>When you send messages or join meetings and webinars on Perplexity, other people and organizations, including third parties outside the meeting, webinar, or message, may be able to see content and information that you share:</p>

                    <p>
                      1.<span>&nbsp;</span>
                      <b>Account Owner: </b>An account owner is the organization or individual that signs up for a Perplexity account. Typically, an account owner designates one or more people (called an “administrator”) to manage their account and can grant privileges to users on the account. Depending on their license with Perplexity, the account owner can authorize additional users on their account, and the account owner can create and/or access the profile information for all users on their account. The account owner and their users can invite others (including guests not on their account and unlicensed participants) to meetings or webinars hosted on their account.
                      <br />
                      Perplexity account owners controls and features that they can use to determine whether certain types of content, such as recordings or Perplexity Team Chat messages, can be created or sent, and what third-party apps can be used, for meetings and webinars hosted on their account. Depending on their settings, account owners and the users they designate can access personal data for participants who join meetings and webinars on their account or send messages to users on their account. Specifically, account owners may have access to:
                    </p>

                    <p>
                      1.<span>&nbsp;</span>
                      <b>Account Usage:</b>
                    </p>

                    <p>
                      1.<span>&nbsp;</span>
                      <b>Product Usage: </b>&nbsp;Information about how users and their devices interact with their account, which may include who sent messages to their users in chat, email addresses, IP addresses, device information, and other information about who joined meetings or webinars on their account, whether their users viewed or downloaded a recording, how long participants participated in their meetings, the time a message was sent, information about Perplexity Phone integrations, and other usage information and feedback metrics.
                    </p>

                    <p>
                      2.<span>&nbsp;</span>
                      <b>Participant List:</b> Information about the participants in a Perplexity meeting, webinar, or chat, which may include name, display name, email address, phone number, and participant or user ID.
                    </p>

                    <p>
                      2.<span>&nbsp;</span>
                      <b>Registration Information: </b>Information provided during registration for a webinar, meeting, Perplexity Room, or recording hosted by the account.
                    </p>

                    <p>
                      3.<span>&nbsp;</span>
                      <b>Perplexity Team Chat and Out-of-Meeting Collaborations:</b> If enabled on their account, account owners and those they authorize can see information about who sent and received Perplexity Team Chat messages, including synced in-meeting messages (e.g., from a dedicated meeting group chat that is synced with Perplexity Team Chat), to users on their account, along with information about the message (for example, date and time, and number of members or participants). Depending on their settings, account owners also can see sender and receiver information, and other messaging data, along with the content of messages sent to and from users on their account (including from in-meeting chat where dedicated meeting group chats are enabled), unless the account owner has enabled Advanced Chat Encryption. Depending on their settings, account owners and those they authorize may also see the content shared through collaborative features, including whiteboards, files, and images shared in Perplexity Team Chat.
                    </p>

                    <p>
                      4.<span>&nbsp;</span>
                      <b>In-Meeting/Webinar Messages: </b>&nbsp;Depending on their settings, account owners can see sender and receiver information, along with the content of messages sent to and from users on their account, in the following circumstances:{" "}
                    </p>

                    <p>
                      1.<span>&nbsp;</span>Messages sent to Everyone in a meeting that is recorded
                    </p>

                    <p>
                      2.<span>&nbsp;</span>Messages sent to the meeting group chat in a meeting when a dedicated meeting group chat is enabled
                    </p>

                    <p>
                      3.<span>&nbsp;</span>Messages sent to panelists in a webinar that is recorded
                    </p>

                    <p>
                      4.<span>&nbsp;</span>Messages sent in dedicated meeting group chats in Team Chat
                    </p>

                    <p>
                      5.<span>&nbsp;</span>Direct messages if the account owner has enabled archiving
                    </p>

                    <p>
                      6.<span>&nbsp;</span>If a participant in a meeting is subject to archiving, their account owner will have access to messages sent to Everyone in the meeting, as well as direct messages sent to that participant. If a participant who is a member of a dedicated meeting group chat is subject to archiving, the member’s account owner will have access to the meeting group chat messages, as well as direct messages sent to that member. If a guest in a meeting with a dedicated meeting group chat is subject to archiving, the guest’s account owner will have access to messages sent to the meeting group chat, as well as direct messages sent to that guest.
                    </p>

                    <p>
                      5.<span>&nbsp;</span>
                      <b>Recordings: </b>Account owners can watch the content of recordings of meetings and webinars hosted on their account. They can also view, share, and enable advanced features for transcripts of meeting audio.
                    </p>

                    <p>
                      6.<span>&nbsp;</span>
                      <b>Polling, Q&amp;A, and Feedback</b>: Account owners can see information about who provided responses to their polls, Q&amp;A, or post meeting or webinar feedback requests, including name and contact information, together with the responses or feedback, unless responses are submitted anonymously.
                    </p>

                    <p>
                      7.<span>&nbsp;</span>
                      <b>Perplexity Email and Perplexity Calendar Content</b>: Depending on their settings, account owners, and designated account administrators, can access email and calendar content sent to and from users on their Perplexity Email or Perplexity Calendar accounts, even if those Perplexity Emails are encrypted.
                    </p>

                    <p>
                      2.<span>&nbsp;</span>
                      <b>Meeting Hosts, Participants, and Invitees: </b>Meeting hosts, participants, and invitees may be able to see your email, display name, profile picture, and presence status, including in Perplexity meetings and in Perplexity’s native calendar service. Meeting hosts, participants, and invitees can also see and (depending on the account owner’s settings) record, save, and share meeting content, audio transcripts, messages sent to Everyone, messages sent to meeting group chats (where enabled, and whether sent in Team Chat or in-meeting), or messages sent to them directly, and files, To-do’s and Task-boards or other information shared with them (including during a meeting, or through a dedicated meeting group chat). Meeting hosts may also share chat transcripts to Perplexity Team Chat, depending on their account owner’s settings. Meeting hosts may also be able to see responses to Q&amp;A and polls generated during the meeting.
                    </p>

                    <p>
                      3.<span>&nbsp;</span>
                      <b>Perplexity Email, Calendar and Perplexity Team Chat Recipients:</b>
                      Recipients of Perplexity Emails and Perplexity Calendar invites can see, save, and share your email and calendar content with others, including by sharing emails to Perplexity Team Chat. If a recipient shares encrypted content with others, for example, by sharing an encrypted Perplexity Email to Perplexity Team Chat, or forwarding an encrypted Perplexity Email to a third-party recipient without a Perplexity Email account, the shared or forwarded content will not be end-to-end encrypted by Perplexity. Those with access to your device and login credentials may be able to see, save and share your email and calendar contents on that device. Recipients of Perplexity Team Chats can see your messages, reactions, emojis, and related content, including content from Perplexity Emails, Perplexity Calendar, and emails from third-party services integrated in the Perplexity client, that you or a third-party choose to share to Perplexity Team Chat, and in-meeting messages that participants send that are synced with Perplexity Team Chat through dedicated meeting group chats. Depending on the account owner’s settings, Perplexity Team Chat recipients may record, save, or share your messages.
                    </p>

                    <p>
                      4.<span>&nbsp;</span>
                      <b>Webinar Panelists and Attendees: </b>Only panelists may be visible to attendees during a webinar, but attendees who agree to unmute can be heard by other attendees. If an attendee agrees to become a panelist during a webinar, they may be visible to other attendees, depending on settings. Panelists and attendees may be able to see the name of a participant who asks a question during a Q&amp;A, along with their question, unless the participant submits the question anonymously.
                    </p>

                    <p>
                      5.<span>&nbsp;</span>
                      <b>Livestreams: </b>Meeting and webinar hosts can choose to livestream to a third-party site or service, which means anyone with access to the livestream will be able to see the meeting or webinar.
                    </p>

                    <p>
                      6.<span>&nbsp;</span>
                      <b>Apps and Integrations: </b>
                    </p>

                    <p>
                      1.<span>&nbsp;</span>Account owners can choose to add Perplexity-developed apps and third-party apps to their account and the Perplexity products they use, including via use of the Perplexity App Marketplace, and they can also control whether their users can add and use specific Perplexity and third-party apps, including in meetings, webinars, and chats hosted on their account.
                    </p>

                    <p>
                      2.<span>&nbsp;</span>Account owners can also choose to integrate other content from third-party services– such as third-party email communications on their corporate account – to apps and services that they use, such as Reports Detective (that provides insights and business analytics related to businesses when they use Perplexity products).{" "}
                    </p>

                    <p>
                      3.<span>&nbsp;</span>Depending on their settings, account owners’, users’ and guests’ personal data and content may be shared with apps and integrations approved by account owners, which may include all of the personal data categories listed above, such as account information, profile and contact information, registration information, participants list, settings, content, product usage, device information, or third-party emails that have been shared with the app.
                    </p>

                    <p>
                      4.<span>&nbsp;</span>Other participants in the meeting may be able to see the app that you are using in a meeting, if the app is receiving content (including audio and video) from the meeting.
                    </p>

                    <p>
                      5.<span>&nbsp;</span>Third-party developers may also integrate or embed Perplexity meetings into their website or app experiences or build versions of Perplexity that enable access to Perplexity products from a third-party app.
                    </p>

                    <p>
                      6.<span>&nbsp;</span>Personal information shared by account owners and users with third-party apps and integrations is collected and processed in accordance with the app developers’ terms and privacy policies, not Perplexity’s.
                    </p>
                  </div>
                  <div id="rights">
                    <h2>Privacy Rights and Choices</h2>

                    <p>
                      <b>Marketing Communications</b>
                      <br />
                      If you don’t want to learn about products and services we or our partners offer, you can opt-out of marketing communications in the communication sent to you (for example, via email or SMS), or by emailing privacy@perplexityonline.com Not all of our communications are for marketing, and you’ll continue to receive messages related to your products and services, such as bills, transactional notices, or customer service.
                    </p>

                    <p>
                      <b>Data Rights</b>
                      <br />
                      If you are in the <u>European Economic Area (EEA), Switzerland, or the UK</u>, or a resident of <u>California or another U.S. state</u> with an applicable privacy law, please refer to the respective dedicated sections below. Otherwise, at your request, and as required by applicable law, we will:
                    </p>

                    <p>
                      1.<span>&nbsp;</span>Inform you of what personal data we have about you that is under our control;
                    </p>

                    <p>
                      2.<span>&nbsp;</span>Amend or correct such personal data or any previous privacy preferences you selected, or direct you to
                      <br />
                      applicable tools; and/or
                    </p>

                    <p>
                      3.<span>&nbsp;</span>Delete such personal data or direct you to applicable tools.
                    </p>

                    <p>In order to exercise any of your rights as to personal data controlled by Perplexity, please send email request to privacy@perplexityonline.com. Where legally permitted, we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, or jeopardize the privacy of others. As an account owner or a user under a licensed account, you may also take steps to affect your personal data by visiting your account and modifying your personal data directly.</p>
                  </div>
                  <div id="children">
                    <h2>Children</h2>

                    <p>Perplexity does not allow children under the age of 16 to sign up for a Perplexity account.</p>

                    <p>For educational organizations that use Perplexity products and services to provide educational services to children under 18, Perplexity’s Children’s Educational Privacy Statement is available as follows:</p>

                    <p>
                      <b>
                        <span>Children’s Educational Privacy Statement</span>
                      </b>
                    </p>

                    <p>
                      <span>This Children’s Educational Privacy Statement (“Statement”) describes the personal data we collect, use or disclose from students under the age of 18 when they receive educational services from schools and other organizations who are using Perplexity Solved Solutions, Inc.’s (“Perplexity”) meetings, webinars, or messaging platform (“Perplexity Products”) to provide educational services to children.&nbsp; This Statement supplements Perplexity’s Privacy Statement and applies only if the account settings selected by a school or organization confirm that it provides educational services to children under 18.</span>
                    </p>

                    <p>
                      <b>
                        <span>What Personal Data Does Perplexity Collect from Students?</span>
                      </b>
                    </p>

                    <p>
                      <span>Personal data is any information from or about an identified or identifiable person, including information that Perplexity can associate with an individual person.&nbsp; We may collect, or process on behalf of schools or other organizations providing educational services, the following categories of personal data when a student uses or interacts with Perplexity Products to receive educational services, such as when they join their classroom or meet with their teacher on Perplexity:</span>
                    </p>

                    <p>
                      <span>
                        &nbsp;&nbsp;&nbsp; <b>Profile and Participant Information:&nbsp; </b>Name, profile picture, contact information, and any other information a school or educational organization allows students to add to their profile or to add when registering for meetings, recordings or webinars hosted on the school or organization’s account.
                      </span>
                    </p>

                    <ul type="disc">
                      <li>
                        <b>
                          <span>Contacts and Calendar Information</span>
                        </b>
                        <span>:&nbsp; Contact lists the school or educational service adds or allows students to use on their account (such as names and email addresses for other students in the school), as well as calendar information added to the account (such as a class schedule or upcoming school events).</span>
                      </li>
                      <li>
                        <b>
                          <span>Settings</span>
                        </b>
                        <span>:&nbsp; Preferences and settings students set when using an educational account, such as microphone, audio and video settings, and screen sharing settings.</span>
                      </li>
                      <li>
                        <b>
                          <span>Device Information</span>
                        </b>
                        <span>:&nbsp; Information about the computers, phones, and other devices students use when joining meetings or webinars or sending messages using Perplexity Products, including device features (like microphone or camera versions and IDs), IP address (which may be used to infer general location at a city or country level) and WiFi information.</span>
                      </li>
                      <li>
                        <b>
                          <span>Meeting, Webinar, and Messaging Content:&nbsp; </span>
                        </b>
                        <span>If the school or educational organization chooses to record meetings or webinars to Perplexity Cloud, Perplexity will store these recordings on behalf of the school or organization.&nbsp; The recordings may contain a student’s voice and image, messages, Q&amp;A, or other content (such as a presentation or To-do’s and Task-boards) shared by a student during a meeting or webinar.&nbsp; Perplexity employees do not access this content unless the school or educational service directs us to do so, or as required for legal, security, or safety reasons.</span>
                      </li>
                      <li>
                        <b>
                          <span>Product Usage:&nbsp; </span>
                        </b>
                        <span>Information about how students and their devices interact with Perplexity Products, such as when they join and leave a meeting, whether they send messages and with whom they message, mouse movements, clicks, keystrokes, or actions (such as mute/unmute or video on/off), and other inputs that help Perplexity understand feature usage, improve product design, and suggest features.</span>
                      </li>
                    </ul>

                    <p>
                      <span>&nbsp;</span>
                    </p>

                    <p>
                      <b>
                        <span>How Do We Use Student Personal Data?</span>
                      </b>
                    </p>

                    <p>
                      <span>Perplexity uses personal data collected from students to conduct the following activities:</span>
                    </p>

                    <ul type="disc">
                      <li>
                        <b>
                          <span>Provide Educational Products and Services:&nbsp; </span>
                        </b>
                        <span>To provide products, features and services for schools and other organizations to use when providing educational services to children, including to customize the product and safety features and settings for a school environment. This may also include using personal data for customer support, which may include accessing audio, video, files, and messages, at the direction of the school or organization.</span>
                      </li>
                      <li>
                        <b>
                          <span>Product Research and Development:&nbsp; </span>
                        </b>
                        <span>To develop, test, and improve Perplexity Products that are used in educational settings.</span>
                      </li>
                      <li>
                        <b>
                          <span>Authentication, Integrity, Security, and Safety:</span>
                        </b>
                        <span>&nbsp; To authenticate accounts and activity, detect, investigate, and prevent malicious conduct or unsafe experiences, address security threats, protect school and public safety, and secure Perplexity Products.</span>
                      </li>
                      <li>
                        <b>
                          <span>Legal Reasons:</span>
                        </b>
                        <span>&nbsp; To comply with applicable law or respond to valid legal process, including from law enforcement or government agencies, to investigate or participate in civil discovery, litigation, or other adversarial legal proceedings, and to enforce or investigate potential violations of our Terms of Service or policies.</span>
                      </li>
                    </ul>

                    <p>
                      <span>Perplexity uses advanced tools to automatically scan content such as virtual backgrounds, profile images, and files uploaded or exchanged through chat, for the purpose of detecting and preventing violations of our terms or policies and illegal or other harmful activity, and its employees may investigate such content where required for legal, safety, or security reasons.</span>
                    </p>

                    <p>
                      <b>
                        <span>How Do We Share Student Personal Data? </span>
                      </b>
                    </p>

                    <p>
                      <span>Perplexity does not disclose student’s data to third parties, except for:</span>
                    </p>

                    <ul type="disc">
                      <li>
                        <span>service providers who help us provide Perplexity Products and technical infrastructure;</span>
                      </li>
                      <li>
                        <span>&nbsp;where required for legal, security, or safety reasons;</span>
                      </li>
                      <li>
                        <span>&nbsp;or to other Perplexity affiliates (such as Perplexity Solved Solutions, Inc., which provides Perplexity dial in option to join meetings) to enable additional products and features for use by schools and educational organizations.</span>
                      </li>
                    </ul>

                    <p>
                      <b>
                        <span>What Student Information Do Schools See and Share on Perplexity Products?</span>
                      </b>
                    </p>

                    <p>
                      <span>
                        Depending on their policies, settings and how they use Perplexity Products to provide educational services, the school or organization providing educational services may be able to see or to share the following personal data from students who join meetings or webinars on their account.&nbsp; The school or other organization’s use and disclosure of student information is subject to the school or educational organization’s policies, not Perplexity’s.&nbsp; Perplexity does not enable children to make personal information publicly available through the use of Perplexity Products.<b>&nbsp;&nbsp;&nbsp;</b>
                      </span>
                    </p>

                    <ul type="disc">
                      <li>
                        <b>
                          <span>Student Usage and Content:&nbsp; </span>
                        </b>
                        <span>Depending on their settings, the school or other organization providing educational services – and the people they designate — can access (i) information about how students and their devices interact with the school or educational organization’s account; (ii) information about the participants who joined classrooms or meetings on their account (including participant name, display name, email address and participant ID); (iii) the content of recordings hosted on their account, as well as a transcript of audio, if enabled; and (iv) information provided in response to polls, Q&amp;A or other content shared during classrooms, webinars and meetings on their account.</span>
                      </li>
                      <li>
                        <b>
                          <span>Teachers, Hosts and Participants</span>
                        </b>
                        <span>: Teachers, hosts and other participants in a classroom or meeting may be able to see students’ email, display name, and profile picture, as well as content and information shared by students during a meeting and webinar.&nbsp; Depending on settings implemented by the school or educational organization, teachers, hosts and participants also may be able to record or save classroom or meeting content, audio transcripts, messages sent to Everyone or to them directly, and files, whiteboards, or other information shared during a classroom or educational meeting.</span>
                      </li>
                      <li>
                        <b>
                          <span>Third-Party App: </span>
                        </b>
                        <span>Schools or educational organizations may choose to install third-party apps to add features or educational services to their use of Perplexity Products, including apps that may receive access to personal information about students and other users on their account.&nbsp; Perplexity does not pre-install any apps on educational accounts, and apps will not be able to access personal information from students receiving educational services on Perplexity Products unless the school or educational organization chooses to approve a specific app.&nbsp; Personal information shared by schools and educational organizations with third-party apps is subject to the school or organization’s policies and the app developers’ terms and privacy policies, not Perplexity’s.</span>
                      </li>
                    </ul>

                    <p>
                      <span>In the United States, before choosing to install third party-apps that may be used by children under the age of 13, schools or educational organizations must obtain parent or guardian consent to the third-party app’s data practices.&nbsp; By installing any third-party app, these schools or other educational organizations agree to obtain such parent or guardian consent and must consent themselves to the disclosure of students’ personal information to the third-party app when installing the app.</span>
                    </p>

                    <p>
                      <span>&nbsp;</span>
                    </p>

                    <p>
                      <b>
                        <span>How to Review and Delete Student Information</span>
                      </b>
                    </p>

                    <p>
                      <span>A school or other educational organization may review and delete a student’s information, if in compliance with any applicable law, from their administrator dashboard.&nbsp; If you are a parent or student, please contact your school or other educational organization to access any personal information, limit a student’s access to Perplexity Products features or services, or delete personal information or the student’s entire profile.&nbsp; A school or other educational organization may also take steps to prevent a student from receiving educational services through the use of Perplexity Products on its account in the future, such as by deleting the student’s profile from the school or other educational organization’s account and limiting the student’s access to use of Perplexity.</span>
                    </p>

                    <p>
                      <b>
                        <span>How to Contact Us</span>
                      </b>
                    </p>

                    <p>
                      <span>If you are a parent or guardian:</span>
                    </p>

                    <ul type="disc">
                      <li>
                        <span>Contact your school administrator if you have questions regarding: </span>
                      </li>
                      <ul type="circle">
                        <li>
                          <span>the school’s management of its Perplexity account</span>
                        </li>
                        <li>
                          <span>the school’s use of your child’s information</span>
                        </li>
                        <li>
                          <span>third-party apps approved by the school who may have access to your child’s information</span>
                        </li>
                        <li>
                          <span>exercising your privacy rights with regards to education records</span>
                        </li>
                      </ul>
                      <li>
                        <span>Contact Perplexity using the below contact information if you have questions regarding: </span>
                      </li>
                      <ul type="circle">
                        <li>
                          <span>this Statement</span>
                        </li>
                        <li>
                          <span>Perplexity’s use of student’s information</span>
                        </li>
                      </ul>
                    </ul>

                    <p>
                      <span>If you are an administrator for an organization providing educational services to students under 18 years of age, contact Perplexity about the information in this Statement using the contact information below.</span>
                    </p>

                    <p>
                      <a href="about:blank">
                        <span>educationalprivacy@perplexity.com</span>
                      </a>
                    </p>

                    <p>&nbsp;</p>

                    <p>&nbsp;</p>
                  </div>
                  <div id="contactus">
                    <h2>How to Contact Us</h2>

                    <p>
                      <span>To exercise your rights, If you have any privacy-related questions or comments related to this Privacy Statement, please send an email to: privacy@perplexityonline.com</span>
                    </p>

                    <p>
                      <span>You can also contact us by writing to the following address:</span>
                    </p>

                    <p>
                      <span>
                        Perplexity Solved Solutions, Inc.
                        <br />
                        Attention: Data Protection Officer
                        <br />
                        5851 Legacy Circle, Suite 600
                        <br />
                        Plano, TX 75024
                      </span>
                    </p>

                    <p>
                      <span>You can contact our Data Protection Officer by sending an email to privacy@perplexityonline.com</span>
                    </p>
                  </div>
                  <div id="retention">
                    <h2>Retention</h2>

                    <p>
                      <span>We retain personal data for as long as required to engage in the uses described in this Privacy Statement, unless a longer retention period is required by applicable law.</span>
                    </p>

                    <p>
                      <span>The criteria used to determine our retention periods include the following:</span>
                    </p>

                    <p>
                      <span>
                        1.<span>&nbsp;</span>
                      </span>
                      <span>The length of time we have an ongoing relationship with you and provide Perplexity products and services to you (for example, for as long as you have an account with us or keep using our products);</span>
                    </p>

                    <p>
                      <span>
                        2.<span>&nbsp;</span>
                      </span>
                      <span>Whether account owners modify or their users delete information through their accounts;</span>
                    </p>

                    <p>
                      <span>
                        3.<span>&nbsp;</span>
                      </span>
                      <span>Whether we have a legal obligation to keep the data (for example, certain laws require us to keep records of your transactions for a certain period of time before we can delete them); or</span>
                    </p>

                    <p>
                      <span>
                        4.<span>&nbsp;</span>
                      </span>
                      <span>Whether retention is advisable in light of our legal position (such as in regard to the enforcement of our agreements, the resolution of disputes, and applicable statutes of limitations, litigation, or regulatory investigation).</span>
                    </p>
                  </div>
                  <div id="dataprotection">
                    <h2>European Data Protection Specific Information</h2>

                    <p>
                      <b>
                        <span>Data Subjects Rights</span>
                      </b>
                    </p>

                    <p>
                      <span>If you are in the EEA, Switzerland, or the UK, your rights in relation to your personal data processed by us as a controller specifically include:</span>
                    </p>

                    <p>
                      <span>
                        1.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Right of access and/or portability</span>
                      </b>
                      <span>: You have the right to access any personal data that we hold about you and, in some circumstances, have that data provided to you so that you can provide or “port” that data to another provider;</span>
                    </p>

                    <p>
                      <span>
                        2.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Right of erasure</span>
                      </b>
                      <span>: In certain circumstances, you have the right to the erasure of personal data that we hold about you (for example, if it is no longer necessary for the purposes for which it was originally collected);</span>
                    </p>

                    <p>
                      <span>
                        3.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Right to object to processing</span>
                      </b>
                      <span>: In certain circumstances, you have the right to request that we stop processing your personal data and/or stop sending you marketing communications;</span>
                    </p>

                    <p>
                      <span>
                        4.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Right to rectification</span>
                      </b>
                      <span>: You have the right to require us to correct any inaccurate or incomplete personal data;</span>
                    </p>

                    <p>
                      <span>
                        5.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Right to restrict processing</span>
                      </b>
                      <span>: You have the right to request that we restrict processing of your personal data in certain circumstances (for example, where you believe that the personal data we hold about you is not accurate or lawfully held).</span>
                    </p>

                    <p>
                      <span>To exercise your rights, please click </span>
                      <a href="about:blank">
                        <span>here</span>
                      </a>
                      <span>. If you have any other questions about our use of your personal data, please send a request at the contact details specified in the </span>
                      <a href="about:blank">
                        <span>How to Contact Us</span>
                      </a>
                      <span> section of this Privacy Statement. Please note that we may request you to provide us with additional information in order to confirm your identity and ensure that you are entitled to access the relevant personal data.</span>
                    </p>

                    <p>
                      <span>You also have the right to lodge a complaint to a data protection authority. For more information, please contact your local data protection authority.</span>
                    </p>

                    <p>
                      <b>
                        <span>Legal Basis for Processing Personal Data</span>
                      </b>
                    </p>

                    <p>
                      <span>We only use your information in a lawful, transparent, and fair manner. Depending on the specific personal data concerned and the factual context, when Perplexity processes personal data as a controller for individuals in regions such as the EEA, Switzerland, and the UK, we rely on the following legal bases as applicable in your jurisdiction:</span>
                    </p>

                    <p>
                      <span>
                        1.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>As necessary for our contract: </span>
                      </b>
                      <span>When we enter into a contract directly with you, we process your personal data on the basis of our contract in order to prepare and enter into the contract, as well as to perform and manage our contract (i.e., providing Perplexity products and services, features and services to account owners, their users, and those they invite to join meetings and webinars hosted on their accounts, and manage our relationship and contract, including billing, compliance with contractual obligations, and related administration). If we do not process your personal data for these purposes, we may not be able to provide you with all products and services;</span>
                    </p>

                    <p>
                      <span>
                        2.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Consistent with specific revocable consents: </span>
                      </b>
                      <span>We rely on your prior consent in order to utilize cookies to engage advertising and analytics partners to deliver tailored advertising and analysis of our website usage. You have the right to withdraw your consent at any time by visiting our cookie management tool, available Cookies Settings;</span>
                    </p>

                    <p>
                      <span>
                        3.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>As necessary to comply with our legal obligations: </span>
                      </b>
                      <span>We process your personal data to comply with the legal obligations to which we are subject for the purposes of compliance with EEA laws, regulations, codes of practice, guidelines, or rules applicable to us, and for responses to requests from, and other communications with, competent EEA public, governmental, judicial, or other regulatory authorities. This includes detecting, investigating, preventing, and stopping fraudulent, harmful, unauthorized, or illegal activity (“fraud and abuse detection”) and compliance with privacy laws;</span>
                    </p>

                    <p>
                      <span>
                        4.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>To protect your vital interests or those of others: We</span>
                      </b>
                      <span> process certain personal data in order to protect vital interests for the purpose of detecting and preventing illicit activities that impact vital interests and public safety, including child sexual abuse material; and</span>
                    </p>

                    <p>
                      <span>
                        5.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>As necessary for our (or others’) legitimate interests, unless those interests are overridden by your interests or fundamental rights and freedoms, which require protection of personal data: </span>
                      </b>
                      <span>We process your personal data based on such legitimate interests to (i) enter and perform the contract with the account owner and/or reseller providing you with the products and services (which includes billing, compliance with contractual obligations, and related administration and support); (ii) develop, test, and improve our products and services and troubleshoot products and services; (iii) ensure authentication, integrity, security, and safety of accounts, activity, and products and services, including detect and prevent malicious conduct and violations of our terms and policies, prevent or investigate bad or unsafe experiences, and address security threats; (iv) send marketing communications, advertising, and promotions related to the products and services; and (v) comply with non-EEA laws, regulations, codes of practice, guidelines, or rules applicable to us and respond to requests from, and other communications with, competent non-EEA public, governmental, judicial, or other regulatory authorities, as well as meet our corporate and social responsibility commitments, protect our rights and property and the ones of our customers, resolve disputes, and enforce agreements.</span>
                    </p>

                    <p>
                      <span>&nbsp;</span>
                    </p>

                    <p>
                      <b>
                        <span>International Data Transfers</span>
                      </b>
                    </p>

                    <p>
                      <span>Perplexity operates globally, which means personal data may be transferred, stored (for example, in a data center), and processed outside of the country or region where it was initially collected where Perplexity or its service providers have customers or facilities – including in countries where meeting participants or account owners hosting meetings or webinars that you participate in or receiving messages that you send are based.</span>
                    </p>

                    <p>
                      <span>Therefore, by using Perplexity products and services or providing personal data for any of the purposes stated above, you acknowledge that your personal data may be transferred to or stored in the United States where we are established, as well as in other countries outside of the EEA, Switzerland, and the UK. Such countries may have data protection rules that are different and less protective than those of your country.</span>
                    </p>

                    <p>
                      <span>We protect your personal data in accordance with this Privacy Statement wherever it is processed and take appropriate contractual or other steps to protect it under applicable laws. Where personal data of users in the EEA, Switzerland, or the UK is being transferred to a recipient located in a country outside the EEA, Switzerland, or the UK which has not been recognized as having an adequate level of data protection, we ensure that the transfer is governed by the European Commission’s standard contractual clauses. Please contact us if you would like further information in that respect.</span>
                    </p>
                  </div>
                  <div id="californianotice">
                    <h2>California Notice at Collection</h2>

                    <p>
                      <b>
                        <span>Categories of Personal Information Perplexity Receives:</span>
                      </b>
                      <span>Perplexity may collect, or process on behalf of our customers, the following categories of personal data, as described above, in the “</span>
                      <a href="about:blank">
                        <span>What Personal Data Do We Receive?</span>
                      </a>
                      <span>” section: identifiers (such as in Account Information, Profile and Participant Information, Contact Information, and Registration Information), financial account information (such as in Account Information); commercial information (such as in Account Information); internet or other electronic network activity information (such as Device Information, Usage Information Regarding Meetings, Webinars, Message, Collaborative Features, and the Website, and Limited Information from Perplexity Email and Calendar Services); audio, electronic, and visual information (such as in Content and Context from Meetings, Webinars, Messaging, and Other Collaborative Features) education information such as from university customers; inferences we derive from the preceding or other information we collect; and sensitive personal information (such as certain categories in Account Information, Content and Context from Meetings, Webinars, Messaging, and Other Collaborative Features.</span>
                    </p>

                    <p>
                      <b>
                        <span>Sources:</span>
                      </b>
                      <span> We receive information from sources as described in the “</span>
                      <a href="about:blank">
                        <span>What Personal Data Do We Receive?</span>
                      </a>
                      <span>” section, including: from you (including through your use of our products and services); from partners; from customers; and from publicly available sources. We collect education information from schools that use our services. Please see our </span>
                      <a href="about:blank">
                        <span>Children’s Educational Privacy Statement</span>
                      </a>
                      <span>for more information.</span>
                    </p>

                    <p>
                      <b>
                        <span>Perplexity’s business and commercial purposes for use:</span>
                      </b>
                      <span>Perplexity uses personal data for the following business and commercial purposes: to provide Perplexity Products and Services; for Product Research and Development; for Marketing and Promotions (Perplexity does not use meeting, webinar, or messaging content, or any content generated or shared as part of other collaborative features for any marketing or promotions); Authentication, Integrity, Security, and Safety; to Communicate with You; and for Legal Reasons. For more information, please see “</span>
                      <a href="about:blank">
                        <span>How We Share Personal Data?</span>
                      </a>
                      <span>” Categories of third parties to whom we disclose Personal Information for business purposes are described in “</span>
                      <a href="about:blank">
                        <span>How We Share Personal Data?</span>
                      </a>
                      <span>”</span>
                    </p>

                    <p>
                      <span>Perplexity may permit advertising and analytics services that are intended to deliver advertising to you and/or analyze your interactions, based on your interactions with our website or app, which may constitute a “sale” or “sharing” of data under California law. See “California &amp; Other U.S. State Privacy Rights” for more information regarding your right to opt-out.</span>
                    </p>

                    <p>
                      <b>
                        <span>Retention:</span>
                      </b>
                      <span> Perplexity retains personal data for as long as required to engage in the uses described in this Privacy Statement unless a longer retention period is required by applicable law. Additional detail on retention criteria can be found under Retention, above.</span>
                    </p>
                  </div>
                  <div id="californiarights">
                    <h2>California &amp; Other U.S. State Privacy Rights</h2>

                    <p>
                      <span>Under some U.S. state laws, including the California Consumer Privacy Act of 2018 (as amended by the California Consumer Privacy Rights Act) (CCPA), residents may have a right to:</span>
                    </p>

                    <p>
                      <span>
                        1.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Access</span>
                      </b>
                      <span> the categories and specific pieces of personal data Perplexity has collected, the categories of sources from which the personal data is collected, the business purpose(s) for collecting the personal data, and the categories of third parties with whom Perplexity has shared personal data;</span>
                    </p>

                    <p>
                      <span>
                        2.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Delete</span>
                      </b>
                      <span> personal data under certain circumstances;</span>
                    </p>

                    <p>
                      <span>
                        3.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Correct</span>
                      </b>
                      <span> personal data under certain circumstances; and</span>
                    </p>

                    <p>
                      <span>
                        4.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Opt out of the “sale”</span>
                      </b>
                      <span>
                        {" "}
                        <b>of personal data or “sharing” of personal data </b>for targeted advertising purposes. We do not sell your personal data in the conventional sense. However, like many companies, we may use advertising and analytics services that are intended to analyze your interactions with our website or app, based on information obtained from cookies or other trackers, including for delivering advertising to you (such as interest-based, targeted, or cross-context behavioral advertising). You can get more information and opt out of the use of cookies and other trackers on our website and app by clicking the <a name="ot_do_not_sell">Do Not Sell My Personal Information</a> link, also on our homepage, and setting your preferences. You will need to set your preferences from each device and each web browser from which you wish to opt out. This feature uses a cookie to remember your preference, so if you clear all cookies from your browser, you will need to re-select your preferred settings. California residents may also set the Global Privacy Control (GPC) to opt out of the “sale” or “sharing” of your personal information for each participating browser system that you use. Perplexity does not have actual knowledge that it “sells” or “shares” the personal information of consumers under 16 years of age.
                      </span>
                    </p>

                    <p>
                      <span>
                        5.<span>&nbsp;</span>
                      </span>
                      <b>
                        <span>Appeal</span>
                      </b>
                      <span> a denial of your request. Some states provide additional rights to their residents. If we decline to process your request, you may have the right to appeal our decision. You can do so by replying directly to our denial or emailing privacy@perplexityonline.com</span>
                    </p>

                    <p>
                      <span>Perplexity will not discriminate against you for exercising any of these rights, which is further in line with your rights under state law.</span>
                    </p>

                    <p>
                      <b>
                        <span>Sensitive Information.</span>
                      </b>
                      <span> Perplexity receives information that may be considered sensitive under some state laws, such as certain Account Information (e.g., financial information, log-in information) , certain Content and Context from Meetings, Webinars, Messaging, and Other Collaborative Features and certain Limited Information from Perplexity Email and Calendar Services (e.g., messaging content in cases described herein) Perplexity processes sensitive personal information to provide Perplexity products and services, for product research and development, for authentication, integrity, security, and safety reasons, to communicate with you, for legal reasons, and with your consent. Perplexity does not use or disclose sensitive personal information (as defined under CCPA) for purposes of inferring characteristics about a consumer, or in any way that would require Perplexity to provide a right to limit under the CCPA. Under certain laws, residents may also be permitted to opt out of certain profiling relating to automated processing analyzing certain categories of an individual’s information that would produce a legal or similarly significant effect. Perplexity does not engage in this type of profiling of individuals.</span>
                    </p>

                    <p>
                      <span>To exercise your rights, please email </span>
                      <a href="about:blank">
                        <span>privacy@perplexity.com</span>
                      </a>
                      <span> To opt out of the use of cookies on our sites for interest-based advertising purposes, follow the instructions above.</span>
                    </p>

                    <p>
                      <span>We will acknowledge receipt of your request within 10 business days, and provide a substantive response within 45 calendar days, or inform you of the reason and extension period (up to a total of 90 days) in writing.</span>
                    </p>

                    <p>
                      <span>These rights are not absolute, are subject to exceptions and limitations, and may not be afforded to residents of all states. In certain cases, we may decline requests to exercise these rights where permitted by law. We will need to verify your identity to process your access, deletion, and correction requests and reserve the right to confirm your state residency. To verify your identity, we may require you to log into your existing Perplexity account (if applicable), give a declaration as to your identity under penalty of perjury, and/or provide additional information, such as providing at least two pieces of personal information relating to your account (which will be compared to information we have, such as profile information) or as we otherwise may already have in our possession, such as your email address and phone number. We will verify your consumer request by comparing the information you provide to information already in our possession and take additional steps to minimize the risk of fraud. You may designate an authorized agent to submit your verified consumer request by providing written permission and verifying your identity, or through proof of power of attorney.</span>
                    </p>

                    <p>
                      <span>To see our Disclosure of Privacy Rights Requests, please click </span>
                      <a href="about:blank">
                        <span>here</span>
                      </a>
                      <span>.</span>
                    </p>

                    <p>
                      <b>
                        <span>California’s Shine the Light Law</span>
                      </b>
                    </p>

                    <p>
                      <span>California Civil Code Section 1798.83, also known as “Shine The Light” law, permits California residents to annually request information regarding the disclosure of your Personal Information (if any) to third parties for the third parties’ direct marketing purposes in the preceding calendar year. We do not share Personal Information with third parties for the third parties’ direct marketing purposes.</span>
                    </p>
                  </div>
                  <div id="changes">
                    <h2>Changes to This Privacy Statement</h2>

                    <p>
                      <span>We may update this Privacy Statement periodically to account for changes in our collection and/or processing of personal data and will post the updated Privacy Statement on our website, with a “Last Updated” date at the top. If we make material changes to this Privacy Statement, we will notify you and provide you an opportunity to review before you choose to continue using our products and services.</span>
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </main>
      </motion.div>
    </HomeLayout>
  );
}
